/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "variables";
@import "@angular/material/theming";
// Plus imports for other components in your app.
@import "ng-viv-ux/theme";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// currently selected theme
@import "themes/decona.scss";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include ng-viv-ux-theme($app-theme);

@import "../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: handwritten;
  src: url(assets/fonts/bickham-script-two_[allfont.net].ttf);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $font;
}
.text-center {
  text-align: center;
}

button:focus {
  border: 0px !important;
  outline: 0px !important;
}

.document-file {
  ngx-dropzone-preview {
    max-width: 100% !important;
    width: 100%;
  }
}

// ckeditor
figure {
  &.table {
    table {
      width: 100%;
      td,
      th {
        border: none;
      }
    }
  }
  &.image {
    img {
      width: 100%;
    }
  }
}
// Styling multi classed Heading Components
app-heading {
  .handwritten-heading {
    font-family: "handwritten", arial;
    font-size: 32px;
  }
  .bolded-heading {
    font-weight: 700;
    font-size: 32px;
  }
}
// google map custom icon
#deleteShape {
  #borderChild {
    background: none padding-box rgb(255, 255, 255);
    border: 1px solid #ccc;
    margin: 4px 0 0 -6px;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
    :hover {
      background: none padding-box rgb(235, 235, 235);
    }
  }
  #contentChild {
    font-size: 12px;
    padding: 5px 8px 5px 8px;
  }
}

.checkout {
  .mat-vertical-stepper-header {
    height: auto;
  }
  .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled="false"] {
    background-color: mat.get-color-from-palette($mat-decona-grey, 25);
    box-shadow: inset 0px 0px 0px 1px mat.get-color-from-palette($mat-decona-grey, 50);
  }
}
// Main menu font
.mat-menu-item{
  font-family: $font;
}

// Checkout cart
.checkout-cart-container {
  .mini-cart {
    background-color: mat.get-color-from-palette($mat-decona-grey, 25);
  }
  .mini-cart-row {
    .image-holder {
      app-image {
        img {
          max-width: 70px !important;
          margin: 0 0 -35px 8px;
        }
      }
    }
    .cart-product-name {
      font-weight: bold;
      a {
        color: mat.get-color-from-palette($mat-decona-grey, 500) !important;
        text-decoration: none;
        font-size: 11px;
      }
    }
    .mini-cart-remove-button {
      button {
        height: 20px;
        mat-icon {
          line-height: 10px;
        }
      }
    }
  }
  .cart-buy-button-holder {
    margin-top: -15px;
    .first-child {
      width: 100%;
      .cart-buy-button {
        color: mat.get-color-from-palette($mat-decona-orange, 50);
        background-color: mat.get-color-from-palette($mat-decona-orange, 400);
        border-radius: 10px;
        font-size: 15px;
        margin: 15px 0 5px 0px;
      }
    }
  }
}

// Mini Cart
.mini-cart-container {
  .bordered-mini-cart {
    border: 1px solid mat.get-color-from-palette($mat-decona-orange, 500);
    border-radius: 5px;
    position: absolute;
    z-index: 100;
    background: #fff;
    margin-top: -40px;
    .orange-border-top {
      border-top: 1px solid mat.get-color-from-palette($mat-decona-orange, 500);
    }
  }
  .mini-cart-row {
    .image-holder {
      app-image {
        img {
          max-width: 70px !important;
          margin: 0 0 -35px 8px;
        }
      }
    }
    .cart-product-name {
      font-weight: bold;
      a {
        color: mat.get-color-from-palette($mat-decona-grey, 500) !important;
        text-decoration: none;
        font-size: 11px;
      }
    }
    .mini-cart-remove-button {
      button {
        height: 20px;
        mat-icon {
          line-height: 10px;
        }
      }
    }
  }
  .cart-buy-button-holder {
    margin-top: -15px;
    .first-child {
      width: 100%;
      .cart-buy-button {
        color: mat.get-color-from-palette($mat-decona-orange, 50);
        background-color: mat.get-color-from-palette($mat-decona-orange, 400);
        border-radius: 10px;
        font-size: 15px;
        margin: 15px 0 5px 0px;
      }
    }
  }
}

// Content details
.content-details-holder {
  .content-details-title {
    border-top: 1px solid mat.get-color-from-palette($mat-decona-orange, 500);
    font-family: "handwritten";
    font-size: 40px;
    padding: 10px;
    app-slot {
      padding-top: 20px;
    }
  }
  .content-details-description {
    padding: 10px;
  }
}

// Products list
.products-list-main-container {
  border-top: 1px solid mat.get-color-from-palette($mat-decona-orange, 500);
  .products-list {
    font-family: $font !important;
    mat-card {
      padding: 16px 0;
      box-shadow: none !important;
      font-family: $font;
    }
    .products-list-title {
      padding-top: 10px;
      h4 {
        text-align: center;
        font-size: 17px;
        font-weight: 400;
      }
    }
    .products-list-price {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
    .products-list-button-holder {
      justify-content: center;
      .products-list-button {
        color: mat.get-color-from-palette($mat-decona-orange, 50);
        background-color: mat.get-color-from-palette($mat-decona-orange, 400);
        border-radius: 10px;
        padding: 0 70px 0 70px;
        font-size: 15px;
        margin: 15px 0 15px 0;
      }
    }
  }
}

// Cart Page
.cart-main-container {
  .heading-holder {
    background-color: mat.get-color-from-palette(
      $mat-decona-orange,
      400
    ) !important;
    app-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      h5 {
        color: #fef2e4 !important;
        line-height: normal !important;
        height: auto !important;
        padding-left: 0px;
        font-size: 18px;
        border-bottom: none !important;
      }
    }
  }
  .readonly-below-table {
    input {
      font-size: 1.25rem;
      font-family: $font !important;
      font-weight: 300;
      color: mat.get-color-from-palette($mat-decona-grey, 500) !important;
    }
    .mat-form-field-infix {
      margin: unset !important;
    }
    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: unset !important;
      padding-top: 5px !important;
    }
  }
  .readonly {
    .mat-form-field-wrapper {
      padding-bottom: 1em;
      .mat-form-field-flex {
        background-color: transparent !important;
        .mat-form-field-infix {
          border-top: unset !important;
          padding: unset !important;
          text-align: center;
          margin: -15px;
          margin-top: 8px;
        }
      }
      .mat-form-field-underline {
        display: none !important;
      }
    }
  }
  .padding-lr-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .cart-product-name a {
    color: mat.get-color-from-palette($mat-decona-grey, 500) !important;
    text-decoration: none;
  }
  .cart-product-name a:hover {
    text-decoration: none;
  }
  .item-holder {
    .formWrapper {
      box-shadow: none !important;
    }
    form {
      padding: 0px !important;
    }
    .arrayRow {
      .row {
        align-items: center;
      }
    }
    display: flex;
    align-items: center;
    app-image {
      box-shadow: none;
      padding: 0px;
      img {
        padding-bottom: 10px;
        max-width: 120px;
        cursor: pointer;
      }
    }
  }
  .cart-buy-button-holder {
    margin-top: -15px;
    .cart-buy-button {
      color: mat.get-color-from-palette($mat-decona-orange, 50);
      background-color: mat.get-color-from-palette($mat-decona-orange, 400);
      border-radius: 10px;
      padding: 0 70px 0 70px;
      font-size: 15px;
      margin: 15px 0 15px 10px;
    }
  }
}

// Subscribe line
.background-orange {
  background-color: mat.get-color-from-palette($mat-decona-orange, 400);
  .formWrapper {
    box-shadow: none !important;

    form {
      padding: 0 !important;
      background-color: mat.get-color-from-palette(
        $mat-decona-orange,
        400
      ) !important;
    }
  }

  .subscribe-label-holder {
    color: mat.get-color-from-palette($mat-decona-orange, 50) !important;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .notify-ad-input {
    .mat-form-field-appearance-fill {
      .mat-form-field-flex {
        background-color: white;
        border-radius: 10px;
      }
      .mat-form-field-underline::before,
      .mat-form-field-ripple {
        display: none;
        width: calc(100% - 20px);
        margin-left: 10px;
      }
    }
  }
  .notify-ad-button-holder {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: mat.get-color-from-palette($mat-decona-orange, 50);
      background-color: mat.get-color-from-palette($mat-decona-grey, 500);
      border-radius: 10px;
      padding: 0 70px 0 70px;
      font-size: 15px;
      margin: 15px 0 15px 0;
    }
  }
}

// Main menu style
.main-menu-container {
  .mat-button-wrapper {
    font-family: $font !important;
    text-transform: uppercase;
    font-weight: 600;
  }
  button {
    padding: 0 10px;
    &:hover {
      .mat-button-wrapper {
        color: mat.get-color-from-palette($mat-decona-orange, 500);
      }
      -webkit-box-shadow: 0px -2px 0px rgb(247 149 29 / 75%);
      -moz-box-shadow: 0px -2px 0px rgb(247 149 29 / 75%);
      box-shadow: 0px -2px 0px rgb(247 149 29 / 75%);
      border-radius: 1%;
    }
  }
  button.hover-trigger{
    z-index: 999 !important;
  }
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover:not(.mat-button-disabled)
    .mat-button-focus-overlay {
    opacity: 0;
  }
}
// Main menu hambuger style
.main-menu {
  .mat-menu-content:not(:empty) {
    
  }
}

// Homepage below banner headings
.below-banner-headings-holder {
  background-color: #f1f1f1;
  .border-right {
    border-right: 1px solid mat.get-color-from-palette($mat-decona-orange, 500) !important;
  }
  .below-banner-heading {
    h5 {
      font-weight: 700;
      .heading-subtitle {
        display: block;
        font-weight: initial;
        font-size: 18px;
      }
    }
  }
}

// One-imaged carousel parallax styling
.single-slide{
 padding: 0 !important;
}
.parallax-content {
  app-html {
    p:first-of-type {
      font-family: "handwritten", arial;
      font-size: 70px;
      border-top: 2px solid mat.get-color-from-palette($mat-decona-orange, 500) !important;
      padding-top: 15px;
      margin-bottom: -25px;
    }
    p:nth-of-type(2) {
      font-family: $font;
      font-size: 70px;
      font-weight: bold;
      margin-bottom: 0rem !important;
    }
    a {
      color: #fef2e4;
      background-color: #f8a53f;
      border-radius: 10px;
      padding: 5px 70px 8px 70px;
      font-size: 18px;
    }
    a:hover {
      text-decoration: none;
    }
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
}
.parallax-slight-right {
  app-html {
    justify-content: end !important;
    width: 75% !important;
  }
}
// Homepage carousel products
.homepage-carousel-main {
  mat-card {
    padding: 5px !important;
  }
}

.orange-border {
  border-top: 1px solid mat.get-color-from-palette($mat-decona-orange, 500);
}
.orange-thick-border {
  border-top: 2px solid mat.get-color-from-palette($mat-decona-orange, 500);
}
.product-details-main-container {
  // Product details
  .details-select-size {
    form {
      padding: 0px !important;
    }
    .formWrapper {
      box-shadow: none;
    }
  }
  .details-title {
    font-size: 20px;
  }
  .details-price {
    font-size: 26px;
    font-weight: 700;
  }
  .details-button-holder {
    .quantity-input {
      .mat-form-field-infix {
        padding-top: 0px !important;
        padding-bottom: 20px !important;
      }
    }
    .details-button {
      color: mat.get-color-from-palette($mat-decona-orange, 50);
      background-color: mat.get-color-from-palette($mat-decona-orange, 400);
      border-radius: 10px;
      padding: 0 25px 0 25px;
      font-size: 20px;
      margin: 15px 0 15px 0;
    }
  }
  .details-shipping-button {
    border: 2px solid mat.get-color-from-palette($mat-decona-orange, 300) !important;
    .mat-button-wrapper {
      color: mat.get-color-from-palette($mat-decona-grey, 300);
    }
    mat-icon {
      font-size: 40px;
      margin-top: -20px;
      margin-right: 25px;
      color: mat.get-color-from-palette($mat-decona-orange, 500);
    }
  }
  .details-image-holder {
    padding-left: 0px;
    img {
      width: 100%;
    }
  }
  .details-product-description {
    padding-bottom: 25px;
    .handwritten-heading {
      border-top: 2px solid mat.get-color-from-palette($mat-decona-orange, 500);
    }

    app-slot {
      padding-top: 20px;
    }
  }
  .details-desc-nmcl-holder {
    .details-nmcl-key::after {
      content: "\003A \00a0 \00a0";
    }
    padding-bottom: 25px;
  }
  .details-heading-last-viewed {
    padding-top: 20px;
  }
  .details-carousel-last-viewed {
    
    mat-card {
      background: transparent;
      padding: 5px;
      .carousel-product-title {
        height: 75px;
      }
    }
  }
}

.carousel-product {
  font-family: $font !important;
  mat-card {
    box-shadow: none !important;
  }

  app-image {
    height: 200px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    
    picture {
      height: 220px;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      
      img {
        width: auto !important;
        max-height: 100% !important;
        max-width: 90% !important;
        transition: all .2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .carousel-product-title {
    font-family: $font !important;
    padding-top: 10px;
    margin-top: 10px;
    height: 75px;
    h4 {
      font-size: 17px;
      font-weight: 400;
    }
  }
  .carousel-product-price {
    font-size: 20px;
    font-weight: 700;
  }
  .carousel-product-button-holder {
    justify-content: center;
    .carousel-product-button {
      color: mat.get-color-from-palette($mat-decona-orange, 50);
      background-color: mat.get-color-from-palette($mat-decona-orange, 400);
      border-radius: 10px;
      padding: 0 70px 0 70px;
      font-size: 15px;
      margin: 15px 0 15px 0;
    }
  }

  .product-carousel {
    max-height: 270px;
    height: 270px;
    width: auto !important;
  }
}

.background-primary {
  background-color: mat.get-color-from-palette($app-accent);
}
// Footer

.footer-container {
  .footer-menu-holder {
    .footer-menu-links {
      display: flex;
      a {
        text-transform: uppercase;
        font-size: 14px;
        color: #fff;
      }
      a:hover {
        text-decoration: none !important;
      }
    }
  }
}
.product-details-main-container {
  .details-image-holder{
    .single-slide{
      img{
        max-height: 400px;
        width: auto;
        display: flex;
        margin: auto;
      }
    }
  }
} 

@media (max-width: 1200px) {
  .cart-main-container {
    .padding-lr-50 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .parallax-content {
    app-html {
      p:first-of-type,
      p:nth-of-type(2) {
        font-size: 40px;
      }
      a {
        font-size: 15px;
      }
    }
  }
  .footer-container {
    app-slot {
      padding-bottom: 30px;
    }
  }
  .product-list {
    padding-left: 15px;
  }
  .products-list-row {
    margin-right: 0px;
  }
}

@media (max-width: 991px) {
  .cart-main-container {
    .cart-buy-button-holder {
      .cart-buy-button {
        margin: 15px 0 15px 0px;
      }
    }
    .item-holder {
      align-items: center;
    }
    .heading-holder {
      align-items: center;
    }
    .image-holder {
      app-image {
        img {
          max-width: 70px !important;
        }
      }
    }
    .cart-product-name {
      font-weight: bold;
      a {
        font-size: 15px;
      }
    }
  }
  .mini-cart-container {
    .bordered-mini-cart {
      margin-top: unset;
    }
  }
  .content-details-title,
  .content-details-description {
    padding: 25px !important;
  }
  .below-banner-headings-holder {
    padding-left: unset;
    padding-right: unset;
    padding: 10px;
    .border-right {
      border-right: none !important;
      border-bottom: 1px solid
        mat.get-color-from-palette($mat-decona-orange, 500) !important;
      margin-bottom: 15px;
    }
  }
  .orange-border-bottom {
    border-bottom: 1px solid mat.get-color-from-palette($mat-decona-orange, 500);
  }
  .orange-border {
    margin-left: 0px;
  }
  .product-details-main-container {
    .details-title {
      padding-top: 20px;
    }
    .details-select-size {
      justify-content: center;
    }

    .shipping-button-holder {
      text-align: center;
    }
    .details-button-holder {
      justify-content: center;
    }
    .details-product-description,
    .details-desc-nmcl-holder {
      padding-left: 15px;
    }
    .details-title,
    .details-price {
      text-align: center !important;
    }
    .details-image-holder {
      padding-left: 15px;
    }
  }
  .products-list-row {
    margin-right: 0px;
  } 
}

@media (max-width: 768px) {
  .cart-main-container {
    .heading-holder {
      app-heading {
        h5 {
          font-size: 15px;
        }
      }
    }
    .arrayRow {
      border-top: none !important;
      padding: unset !important;
    }
    .item-holder {
      justify-content: space-evenly;
    }
    .heading-holder {
      justify-content: center;
    }
  }

  .parallax-content {
    app-html {
      p:first-of-type {
        padding-top: 5px;
        margin-bottom: 0px;
      }
      p:first-of-type,
      p:nth-of-type(2) {
        font-size: 26px;
      }
      a {
        font-size: 12px;
      }
    }
  }
  .background-orange {
    .subscribe-label-holder {
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 576px) {
  .filter-toggle,
  .order-create {
    app-slot {
      width: 100%;
      button {
        width: 100%;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .parallax-content {
    app-html {
      p:first-of-type {
        padding-top: 5px;
        margin-bottom: -5px;
      }
      p:first-of-type,
      p:nth-of-type(2) {
        font-size: 20px;
      }
      a {
        font-size: 10px;
      }
    }
  }
}

.product-list-card-img {
  max-height: 250px !important;
  width: auto !important;
}

.sz-btn, .sz-btn:focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  border: 2px solid #f7951d !important;
  border-radius: 5px !important;
  transition: all .2s ease-in-out;
}

.sz-btn:hover {
  color: rgba(255, 255, 255, 1);
  background-color: #f7951d;
  border-radius: 5px !important;
}

.mat-menu-item {
  transition: all .2s ease-in-out;
}

.mat-menu-item:hover:not([disabled]) {
  background-color: #f7951d;
  color: #fff;
}

.parallax-content app-html a {
  
  transition: all .2s ease-in-out;

  &:hover {
    background-color: #f7951d;
  }
}

.mainMenu {
  align-items: center;

  a { 
    color: inherit !important;
    padding-left: 20px;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }
  p {
    border-bottom: 2px solid rgba(247, 149, 29, 0);
    transition: all .2s ease-in-out;
    
    &:hover {
      border-bottom: 2px solid rgb(247, 149, 29, 1);
    }
  }
  
  
  
  
  .dropdownMenu {
    
    background-color: rgba(250, 250, 250, $alpha: 0.85);
    border-radius: 8px;
    min-width: 185px;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    box-shadow: 5px 5px 5px rgbA(150, 150, 150, 0.5);

    p {
      border-bottom: 2px solid rgba(247, 149, 29, 0);
      transition: all .2s ease-in-out;
      
      &:hover {
        color: #fff;
        background: rgb(247, 149, 29);
        border-radius: 8px;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    a {
      padding-left: 0 !important;
    }
    li {
      padding: 0.3rem;
    }

    ul {
      position: relative !important;
      background: none;
      min-width: unset !important;
      border: none;
      box-shadow: none;

      li {
        padding-bottom: 0 !important;
      }
    }
  }

  .li-level-1 {
    font-weight: 600;
    
    p {
      margin: 0 !important;
    }
  }
}

.continue-btn {
  figure:nth-child(2) {
    padding-top: 3rem;
  }
}